import React from 'react'
import SearchCard from './SearchCard'
import SearchBanner from './SearchBanner'
import Header from './Header'
import Footer from './Footer'
import { useParams } from 'react-router-dom';
import jsonData from '../json/Data.json';
import FooterMenu from './FooterMenu'
import { Exception } from 'sass'
import { Helmet } from 'react-helmet'

function SearchPage() {
    const { location } = useParams();
    let filteredData = jsonData;

    if (location) {
        const lowercaseLocation = location.toLowerCase();
        filteredData = jsonData.filter((packageData) =>
            packageData.places_covered.toLowerCase().includes(lowercaseLocation)
        );
        if (filteredData.length === 0) {
            filteredData = jsonData;
        }
    }

    return(
        <>
            <div style={{overflowX:"hidden"}}>
                <Header dark={true}/>
                <Helmet>
                    <meta name="robots" content="noindex, nofollow"/>
                </Helmet>
                <div className='searchpage-header-gap' style={{minWidth:"100%", position:"relative", display:"block", overflowX:"hidden"}}>
                    <div style={{ paddingTop:"1%", paddingBottom:"1%", fontSize:"12px", fontFamily:"Montserrat", margin:"0 -7%", boxShadow:"rgba(0, 0, 0, 0.1) 1px 0px 5px 2px inset", backgroundColor:"#fafafa",color:"black", fontFamily:"Lato", padding:"1% 5%"}} >
                        <a href="/" style={{textDecoration:"underline", paddingLeft:"5%"}}>Home</a> &gt; <a href="/search" style={{textDecoration:"underline"}}> Destination</a> &gt; <a href="/package" style={{textDecoration:"underline", fontWeight:"bolder"}}> {location}</a>
                    </div>
                    <div>
                        <SearchBanner location={location} img={filteredData[0].img}/>
                    </div>
                    <div style={{backgroundColor:"#fafafa", width:"100%", display:"flex", flexDirection:"column", alignItems:"center", alignContent:"center"}}>
                        <div className='top-trips-text-searchpg font-size-48-24' style={{fontFamily:"Maven Pro"}}>
                            Top&nbsp;<span style={{textDecoration:"capitalize"}}>{location}</span>&nbsp;trips
                        </div>
                        {filteredData.map((data ,index) => (
                            <SearchCard data={data}/>
                        ))}
                    </div>
                </div>
            </div>
            <FooterMenu/>
            <Footer/>
        </>
    )
}

export default SearchPage