import React from "react";

function HomepageVideoSlider({data}){
    return(
        <>
            {data.map((data ,index) => (
                <div style={{margin:"5vh 2vh", position:"relative", alignItems:"center",alignContent:"center", display:"flex", justifyContent:"center"}}>
                    <img className="img-cards-home" src={data.img} style={{borderRadius:"20px", minHeight:"25vh",objectFit:"fill", boxShadow: "0 1px 4px 0 rgba(63,65,68,0.7)"}}>
                    </img>
                    <div className="offer" style={{position:"absolute", top:"60%", left:"5%", transform:"translate(-50%, -50%),", lineHeight:"20px"}}>
                        {/* <div style={{color:"rgba(0, 0, 0, 1)", padding:"0.5vh 2vh",  background:"linear-gradient(to bottom,#12826a, #0c4836)", borderRadius:"30px", display:"inline-block"}}>
                                {data.badge}
                        </div> */}
                        {/* <div style={{fontSize:"18px", color:"white", textTransform:"capitalize"}}>
                                {data.title}
                        </div>
                        <div style={{fontSize:"12px", color:"white"}}>
                                {data.offers}
                        </div> */}
                    </div>
                </div>
            ))}
        </>
    )
}

export default HomepageVideoSlider