import {React, useState} from "react";
import '../styles/FooterMenu.scss'
import '../styles/Homepage.scss'
// ZZAA
import logoPng from '../assets/logo1.png.avif'
// import logoPng from '../assets/Horizontal L.png'
import partnersWeb from '../assets/partners-web.png.avif'
import partnersMobile from '../assets/partner-mobile.png.avif'

function FooterMenu(){
    const [isOpen, setIsOpen] = useState(Array(20).fill(false)); // Initialize an array with 10 dropdowns, change the number as per your requirement

    const toggleDropdown = (index) => {
        const updatedOpenState = [...isOpen]; // Create a copy of the state array
        updatedOpenState[index] = !updatedOpenState[index]; // Toggle the state of the current dropdown
        setIsOpen(updatedOpenState); // Update the state array
        console.log(isOpen[index]+'*****')
    };

    const menuItems = [
        { label: 'India Packages', subcategories: ['Tamil Nadu', 'Goa', 'Kerala', 'Telangana', 'Andhra Pradesh', 'Delhi', 'Mumbai', 'Himachal', 'Jammu'] },
        { label: 'South Asian Vacation Packages', subcategories: ['International Tour', 'Singapore', 'Sri Lanka', 'Maldives', 'Thailand', 'Andaman', 'Vietnam', 'Mauritius', 'Lakshadweep'] },
        { label: 'Themed destinations', subcategories: ['International Tour', 'International Honeymoon', 'International Family', 'International Beach', 'Adventure', 'Summer Holiday', 'International Visa on arrival', 'International Budget', 'International Luxury'] },
        { label: 'Holiday destinations', subcategories: ['Australia Tour', 'Europe Tour', 'Dubai Tour', 'New Zealand', 'Singapore Tour', 'Thailand Tour', 'Bali', 'Maldives', 'Seychelles', 'Malaysia', 'South Africa', 'Cambodia', 'USA', 'Sri Lanka Tour', 'Bangkok Tour', 'Vietnam Tour', 'Phuket Tour', 'Krabi Tour', 'Pattaya Tour', 'Koh Samui Tour', 'Chiang Mai Tour', 'Phi Phi Island Tour', 'Koh Phangan Tour', 'Koh Tao', 'Koh Chang Tour', 'Thailand Solo Travel', 'Bali Solo Travel', 'Bali Photoshoot', 'Bali Private Pool Villa', 'Japan Tour', 'Egypt', 'Azerbaijan', 'Jordan Tour', 'Baku Tour', 'Bali Swing', 'Bali New Year', 'Bali Baby Moon', 'Bali Safari', 'Bali Scuba Diving'] },
        { label: 'Europe Specials', subcategories: ['France Tour', 'Italy Tour', 'Austria Tour', 'Switzerland', 'Germany Tour', 'Greece', 'Spain', 'UK', 'Europe Honeymoon', 'Turkey', 'London', 'Croatia', 'Amsterdam Tour', 'Iceland Tour', 'Paris Tour', 'Czech Republic Tour', 'Paris Switzerland Italy Tour', 'Norway Tour', 'Finland Tour', 'Denmark Tour', 'Eastern Europe Tour', 'Central Europe Tour', 'Prague Vienna Budapest Tour', 'Portugal Tour', 'Scandinavia Tour', 'Italy Greece Tour', 'Istanbul Tour', 'Santorini Tour', 'Sweden Tour'] },
        { label: 'Honeymoon Packages', subcategories: ['Maldives Honeymoon', 'Dubai Honeymoon', 'Mauritius Honeymoon', 'Switzerland Honeymoon', 'Greece Honeymoon', 'Turkey Honeymoon', 'Sri Lanka Honeymoon', 'Bali Honeymoon', 'Italy Honeymoon', 'Spain Honeymoon', 'Seychelles Honeymoon', 'Thailand Honeymoon', 'Singapore Honeymoon', 'France Honeymoon', 'Paris Honeymoon', 'Iceland Honeymoon', 'Vietnam Honeymoon', 'Phuket Honeymoon', 'Bangkok Honeymoon'] },
        { label: 'Packages from Departure City', subcategories: ['International Tour Packages from Bangalore', 'International Tour Packages from Kolkata', 'International Tour Packages from Chennai', 'International Tour Packages from Ahmedabad', 'International Tour Packages from Kochi', 'International Tour Packages from Delhi'] },
        { label: 'India Packages', subcategories: ['Bali Thailand Tour', 'Singapore Bali', 'Malaysia and Bali', 'Singapore Malaysia and Bali', 'Singapore Malaysia Thailand Tour', 'Singapore Bali Thailand Tour', 'Singapore Malaysia Tour', 'Bali and Vietnam', 'Vietnam Cambodia', 'Vietnam and Singapore', 'Thailand and Vietnam Tour', 'Thailand Malaysia Tour', 'Spain and Greece Vacation'] },
    ];

    return(
        <>
            <footer style={{minWidth:"100%", background:"black", color:"white", display:"flex", flexDirection:"column", flexWrap:"wrap", justifyContent:"center", alignContent:"center", marginTop:"2%"}}>
                <div className="max-width-70-90" style={{display:"flex",flexWrap:"wrap", alignContent:"center", flexDirection:"column"}}>
                    <div className="font-size-32-20" style={{display:"flex", justifyContent:"center", alignContent:"center", fontWeight:"bolder", textAlign:"center"}}>
                        Partnered with the best in the industry
                    </div>
                    <div style={{borderBottom:"1px solid grey"}}>
                        <img className="display-web" src={partnersWeb} style={{minWidth:"100%", maxWidth:"100%", paddingBottom:"5%"}}/>
                        <img className="display-mobile" src={partnersMobile} style={{minWidth:"100%", maxWidth:"100%"}}/>
                    </div>
                    <div className="display-web" style={{display:"flex", flexDirection:"row", justifyContent:"space-between", marginTop:"2%"}}>
                        <div className="display-web" style={{width:"55%", textAlign:"justify"}}>
                            Welcome to All India Tours and Travels, your gateway to unforgettable journeys and unparalleled experiences across the diverse landscapes of India. Our company is committed to providing exceptional travel services, creating memories that last a lifetime.
                            Explore the enchanting beauty of India with our meticulously crafted itineraries covering a plethora of destinations. From the majestic Himalayas to the serene beaches of Goa, from the historical wonders of Delhi to the vibrant culture of Rajasthan, we curate experiences that showcase the rich tapestry of India.
                        </div>
                        <div  style={{display:"flex", justifyContent:"center", alignContent:"center", width:"45%"}}>
                            <img className="display-web" src={logoPng} style={{maxWidth:"400px", objectFit:"contain"}}/>
                        </div>
                    </div>
                    <div className="display-mobile"style={{display:"flex", flexDirection:"row", marginTop:"2%", borderBottom:"1px solid grey"}}>
                        <div className="display-mobile" style={{width:"100%", textAlign:"justify"}}>
                            Welcome to All India Tours and Travels, your gateway to unforgettable journeys and unparalleled experiences across the diverse landscapes of India. Our company is committed to providing exceptional travel services, creating memories that last a lifetime.
                            Explore the enchanting beauty of India with our meticulously crafted itineraries covering a plethora of destinations. From the majestic Himalayas to the serene beaches of Goa, from the historical wonders of Delhi to the vibrant culture of Rajasthan, we curate experiences that showcase the rich tapestry of India.
                        </div>
                    </div>
                    <div>
                        {menuItems.map((menuItem, index) => (
                            <div key={index} style={{ marginTop: "2%", fontFamily: "Lato", fontWeight: "bolder", lineHeight: "2em", wordWrap: "normal", borderBottom: "1px solid grey" }}>
                                <div>
                                    <div>
                                        <button onClick={() => toggleDropdown(index)} style={{ display: "flex", justifyContent: "space-between", width: "100%", backgroundColor: "black", border: "none" }}>
                                            <div style={{ color: "white" }}>{menuItem.label}</div>
                                            {isOpen[index] ? <span style={{ color: "white" }}>&#9650;</span> : <span style={{ color: "white" }}>&#9660;</span>}
                                        </button>
                                    </div>
                                    {isOpen[index] && (
                                        <div className="dropdown-content">
                                            <ul className="ul-footer">
                                                {menuItem.subcategories.map((subcategory, subIndex) => (
                                                    <li key={subIndex}><a href={`/search/${subcategory}`} style={{color:"white"}}>{subcategory} Packages</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    </div>
                    <div className="max-width-70-90" style={{display:"flex", flexDirection:"row", flexWrap:"wrap", justifyContent:"space-between", lineHeight:"2em", fontWeight:"bolder", fontSize:"10px"}}>
                        <div>
                            <div style={{color:"white"}}>Welcome Tours and Travels</div>
                            <ul style={{listStyle:"none", textIndent:"none", marginLeft:"0", paddingLeft:"0"}}>
                                <li><a style={{color:"white"}} href="/aboutus">About Us</a></li>
                                <li>Careers</li>
                                <li>Blog</li>
                                <li>Testimonials</li>
                                <li>Press</li>
                            </ul>
                        </div>
                        <div>
                            <div style={{color:"white"}}>Policy</div>
                            <ul style={{listStyle:"none", textIndent:"none", marginLeft:"0", paddingLeft:"0"}}>
                                <li><a style={{color:"white"}} href="/faq">Frequently asked questions</a></li>
                                <li>Terms & Conditions</li>
                                <li><a style={{color:"white"}} href="/privacy">Privacy</a></li>
                                <li>Cancellations</li>
                                <li><a style={{color:"white"}} href="/sitemap.xml">Sitemap</a></li>
                                <li><a style={{color:"white"}} href="/contactUs">Contact Us</a></li>
                            </ul>
                        </div>
                        <div>
                            <div style={{color:"white"}}>Talk to us</div>
                            <ul style={{listStyle:"none", textIndent:"none", marginLeft:"0", paddingLeft:"0"}}>
                                <li>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 16 16" fill="#fff"><path fill-rule="evenodd" d="M2.3 3.6c.274-.364.71-.6 1.2-.6h9c.49 0 .926.236 1.2.6L8 7.875 2.3 3.6zM2 4.625V11.5A1.5 1.5 0 003.5 13h9a1.5 1.5 0 001.5-1.5V4.625L8.3 8.9a.5.5 0 01-.6 0L2 4.625zM3.5 2A2.5 2.5 0 001 4.5v7A2.5 2.5 0 003.5 14h9a2.5 2.5 0 002.5-2.5v-7A2.5 2.5 0 0012.5 2h-9z" clip-rule="evenodd"></path></svg>
                                    director@allindiatours.com
                                </li>
                                <li>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 16 16" fill="#fff"><path fill-rule="evenodd" d="M2.878 1.665a1.5 1.5 0 012.206.092L6.66 3.62a1.5 1.5 0 01.127 1.764l-.559.894c-.096.154-.09.321-.013.437.517.786 1.71 2.323 3.521 3.138a.425.425 0 00.408-.05l.524-.35a1.5 1.5 0 011.664 0l1.82 1.214c.567.378.764 1.12.46 1.729-.78 1.557-2.56 2.5-4.288 1.883-4.131-1.477-7.08-4.301-8.717-7.867-.71-1.548-.189-3.287.952-4.428l.319-.32zm1.442.738a.5.5 0 00-.735-.031l-.32.32c-.91.91-1.249 2.214-.749 3.303 1.524 3.32 4.267 5.956 8.144 7.342 1.166.417 2.461-.198 3.057-1.389a.351.351 0 00-.12-.449l-1.82-1.213a.5.5 0 00-.554 0l-.525.35c-.39.26-.907.339-1.372.13-2.068-.931-3.39-2.656-3.946-3.5a1.401 1.401 0 010-1.518l.56-.894a.5.5 0 00-.043-.588L4.32 2.403z" clip-rule="evenodd"></path></svg>
                                    +91 73733 39933
                                </li>
                                <li>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 16 16" fill="#fff"><path fill-rule="evenodd" d="M2 8a6 6 0 112.97 5.18.5.5 0 00-.387-.051l-2.352.65.672-2.299a.5.5 0 00-.052-.397A5.97 5.97 0 012 8zm6-7a7 7 0 00-6.117 10.405L1.02 14.36a.5.5 0 00.613.622l3.017-.834A7 7 0 108 1zm1.202 8.335l.261-.227a.498.498 0 01.6-.042l.641.419c.03.02.057.041.082.066l.068.068a.505.505 0 01-.042.75l-.54.434a.817.817 0 01-.623.19 5.138 5.138 0 01-2.903-1.48C5.684 8.446 5.213 7.125 5.03 6.444a.888.888 0 01.197-.806l.392-.461a.498.498 0 01.758-.003l.37.429.393.467c.19.226.142.57-.103.734a.444.444 0 00-.18.526c.21.553.754 1.647 1.885 2.097.157.063.333.02.461-.09z" clip-rule="evenodd"></path></svg>
                                    +91 73733 39933
                                </li>
                            </ul>
                        </div>
                        <div>
                            <div style={{color:"white"}}>Social</div>
                            <ul style={{listStyle:"none", textIndent:"none", marginLeft:"0", paddingLeft:"0"}}>
                                <li>
                                    <a href='https://www.facebook.com/welcometoursindia/' style={{color:"white"}}>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 24 24" fill="#fff"><path fill-rule="evenodd" d="M8 7c0-3.352 2.648-6 6-6h3a1 1 0 011 1v4a1 1 0 01-1 1h-3v2h3a1 1 0 01.97 1.242l-1 4A1 1 0 0116 15h-2v6a1 1 0 01-1 1H9a1 1 0 01-1-1v-6H6a1 1 0 01-1-1v-4a1 1 0 011-1h2V7zm6-4c-2.248 0-4 1.752-4 4v3a1 1 0 01-1 1H7v2h2a1 1 0 011 1v6h2v-6a1 1 0 011-1h2.22l.5-2H13a1 1 0 01-1-1V7c0-.527.18-1.044.568-1.432C12.956 5.18 13.473 5 14 5h2V3h-2z" clip-rule="evenodd"></path></svg>
                                    Facebook
                                    </a>
                                </li>
                                <li>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 24 24" fill="#fff"><path fill-rule="evenodd" d="M19.465 4.253a8.445 8.445 0 001.97-1.025 1 1 0 011.542 1.039c-.29 1.325-1.004 2.528-1.886 3.482v.375c0 5.47-2.672 9.472-6.517 11.468-3.817 1.981-8.692 1.936-13.051-.433a1 1 0 01.516-1.878 9.688 9.688 0 004.196-.77c-1.277-.806-2.212-1.752-2.873-2.772-.926-1.43-1.275-2.946-1.338-4.304-.063-1.354.157-2.571.387-3.443.131-.495.284-.995.496-1.463a1 1 0 011.715-.182c1.528 2.064 3.825 3.363 6.38 3.571.035-1.382.608-2.68 1.8-3.646 1.893-1.723 4.8-1.663 6.663-.019zM4.24 6.933c-.15.68-.26 1.516-.22 2.409.051 1.086.327 2.239 1.02 3.31.69 1.065 1.833 2.112 3.721 2.933a1 1 0 01.148 1.755 12.089 12.089 0 01-2.9 1.387c2.726.595 5.424.241 7.643-.91 3.155-1.638 5.438-4.938 5.438-9.693v-.781a1 1 0 01.3-.715c.118-.115.232-.235.34-.358-.096.029-.193.057-.291.083a1 1 0 01-1.016-.314c-1.094-1.27-3.08-1.393-4.29-.274a1.032 1.032 0 01-.055.047C13.33 6.412 13 7.185 13 8.054v.89a1 1 0 01-.973 1 10.76 10.76 0 01-7.786-3.011z" clip-rule="evenodd"></path></svg>
                                    Twitter
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/welcome_tours_and_travels/" style={{color:"white"}}>
                                        <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 24 24" fill="#fff"><path fill-rule="evenodd" d="M7.5 4A3.502 3.502 0 004 7.5v9C4 18.432 5.568 20 7.5 20h9c1.932 0 3.5-1.568 3.5-3.5v-9C20 5.568 18.432 4 16.5 4h-9zM2 7.5C2 4.464 4.464 2 7.5 2h9C19.536 2 22 4.464 22 7.5v9c0 3.036-2.464 5.5-5.5 5.5h-9A5.502 5.502 0 012 16.5v-9zm10.434 1.533a2.826 2.826 0 00-.866 0l-.007.002a2.99 2.99 0 00-2.527 3.402v.005a2.992 2.992 0 003.408 2.525 2.992 2.992 0 002.526-3.405 3.01 3.01 0 00-2.53-2.528h-.004zm.302-1.977a4.825 4.825 0 00-1.468 0 4.99 4.99 0 00-4.212 5.677 4.992 4.992 0 005.675 4.213 4.992 4.992 0 004.216-5.675l-.001-.003a5.01 5.01 0 00-4.21-4.212zM17 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
                                        Instagram
                                    </a>
                                </li>
                                <li>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 16 16" fill="#fff"><path fill-rule="evenodd" d="M4 2.5a.5.5 0 11-1 0 .5.5 0 011 0zm1 0a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-3 3a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v8a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-8zM3 6v7h1V6H3zm3-.5a.5.5 0 01.5-.5h2a.5.5 0 01.498.452A3.329 3.329 0 0114 8.328V13.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-4a1 1 0 10-2 0v4a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-8zM8 6H7v7h1V9.5a2 2 0 114 0V13h1V8.328a2.328 2.328 0 00-3.975-1.646l-.171.172A.5.5 0 018 6.5V6z" clip-rule="evenodd"></path></svg>
                                   LinkedIn
                                </li>
                                <li>
                                    <svg style={{marginRight:"0.5em"}} width="1em" height="1em" viewBox="0 0 16 16" fill="#fff"><path fill-rule="evenodd" d="M8.492 2.135a3.72 3.72 0 015.346-.005c1.543 1.511 1.529 4.026.08 5.593l-.008.009c-.778.8-2.398 2.458-2.398 2.458-1.716 1.363-4.115.944-5.412-.78a.5.5 0 01.8-.602c.98 1.304 2.728 1.58 3.966.618l2.322-2.387c1.098-1.192 1.084-3.088-.053-4.198l-.01-.009a2.72 2.72 0 00-3.916 0L8.067 4.005a.5.5 0 01-.717-.697l1.142-1.173zM9.9 6.59C8.603 4.866 6.204 4.447 4.488 5.81c0 0-1.62 1.659-2.398 2.458l-.008.01c-1.449 1.566-1.463 4.08.08 5.592a3.72 3.72 0 005.346-.005l1.142-1.173a.5.5 0 00-.717-.697L6.79 13.168a2.72 2.72 0 01-3.917 0l-.009-.01c-1.137-1.11-1.152-3.005-.053-4.197l2.322-2.387c1.238-.962 2.986-.686 3.966.618a.5.5 0 10.8-.601z" clip-rule="evenodd"></path></svg>
                                    YouTube
                                </li>
                                <li style={{marginTop:"2%"}}>
                                    Welcome Tours and Travels Chennai Private Ltd. © 2024 <br/> all rights reserved.
                                </li>
                            </ul>
                        </div>
                    </div>
                    
            </footer>
            <footer className="new_footer_area bg_color" style={{padding:"0 5% 0 5%", display:"none"}}>
                <div className="new_footer_top font-size-20-13">
                    <div style={{position:"absolute", top:"0", left:"0", minWidth:"100%", padding:"20px 20px 10px 20px", zIndex:"9999", maxWidth:"60%"}}>
                        <img className="logo-footer" src={logoPng}/>
                        {/* <div style={{fontSize:"12px"}}>Explore Beyond Boundaries, Travel Beyond Imagination.</div> */}
                        <div style={{minWidth:"100%", borderTop:"1px solid lightgrey", borderBottom:"1px solid lightgrey"}}></div>
                    </div>
                    <div className="padding-top-6-3" style={{display:"flex", alignItems:"center", color:"grey"}}>
                        <svg style={{maxWidth:"20px", marginRight:"5px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 18L9 12M20 18L15 12M3 8L10.225 12.8166C10.8665 13.2443 11.1872 13.4582 11.5339 13.5412C11.8403 13.6147 12.1597 13.6147 12.4661 13.5412C12.8128 13.4582 13.1335 13.2443 13.775 12.8166L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        {/* ZZAA */}
                        {/* operations@welcometravels.co.za */}
                        director@allindiatours.com
                    </div>
                    <div style={{display:"flex", alignItems:"center", color:"grey", paddingTop:"1%"}}>
                    <svg style={{maxWidth:"20px", marginRight:"5px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16.5562 12.9062L16.1007 13.359C16.1007 13.359 15.0181 14.4355 12.0631 11.4972C9.10812 8.55901 10.1907 7.48257 10.1907 7.48257L10.4775 7.19738C11.1841 6.49484 11.2507 5.36691 10.6342 4.54348L9.37326 2.85908C8.61028 1.83992 7.13596 1.70529 6.26145 2.57483L4.69185 4.13552C4.25823 4.56668 3.96765 5.12559 4.00289 5.74561C4.09304 7.33182 4.81071 10.7447 8.81536 14.7266C13.0621 18.9492 17.0468 19.117 18.6763 18.9651C19.1917 18.9171 19.6399 18.6546 20.0011 18.2954L21.4217 16.883C22.3806 15.9295 22.1102 14.2949 20.8833 13.628L18.9728 12.5894C18.1672 12.1515 17.1858 12.2801 16.5562 12.9062Z" fill="#1C274C"></path> </g></svg>
                        {/* ZZAA */}
                        {/* +27837789189 */}
                        +91 73733 39933
                    </div>
                    <div style={{display:"flex", alignItems:"center", color:"grey", paddingTop:"1%"}}>
                    <svg style={{maxWidth:"20px", marginRight:"5px"}} viewBox="0 0 1024 1024" fill="#000000" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M512 1012.8c-253.6 0-511.2-54.4-511.2-158.4 0-92.8 198.4-131.2 283.2-143.2h3.2c12 0 22.4 8.8 24 20.8 0.8 6.4-0.8 12.8-4.8 17.6-4 4.8-9.6 8.8-16 9.6-176.8 25.6-242.4 72-242.4 96 0 44.8 180.8 110.4 463.2 110.4s463.2-65.6 463.2-110.4c0-24-66.4-70.4-244.8-96-6.4-0.8-12-4-16-9.6-4-4.8-5.6-11.2-4.8-17.6 1.6-12 12-20.8 24-20.8h3.2c85.6 12 285.6 50.4 285.6 143.2 0.8 103.2-256 158.4-509.6 158.4z m-16.8-169.6c-12-11.2-288.8-272.8-288.8-529.6 0-168 136.8-304.8 304.8-304.8S816 145.6 816 313.6c0 249.6-276.8 517.6-288.8 528.8l-16 16-16-15.2zM512 56.8c-141.6 0-256.8 115.2-256.8 256.8 0 200.8 196 416 256.8 477.6 61.6-63.2 257.6-282.4 257.6-477.6C768.8 172.8 653.6 56.8 512 56.8z m0 392.8c-80 0-144.8-64.8-144.8-144.8S432 160 512 160c80 0 144.8 64.8 144.8 144.8 0 80-64.8 144.8-144.8 144.8zM512 208c-53.6 0-96.8 43.2-96.8 96.8S458.4 401.6 512 401.6c53.6 0 96.8-43.2 96.8-96.8S564.8 208 512 208z" fill=""></path></g></svg>
                        {/* ZZAA */}
                        {/* 194 Titren Road, Seaview, Durban 4093 */}
                        1 Woods Road, Chennai, India
                    </div>
                    <div className="container footer-social-icons">
                    <div className="row">
                        <div className="col-lg-3 col-md-6">
                        <div
                            className="f_widget company_widget wow fadeInLeft"
                            data-wow-delay="0.2s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.2s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            {/* <h3 className="f-title f_600 t_color f_size_18">Get in Touch</h3>
                            <p>Don’t miss any updates of our new templates and extensions.!</p> */}
                            {/* <form
                            action="#"
                            className="f_subscribe_two mailchimp"
                            method="post"
                            noValidate="true"
                            _lpchecked={1}
                            >
                            <input
                                type="text"
                                name="EMAIL"
                                className="form-control memail"
                                placeholder="Email"
                            />
                            <button className="btn btn_get btn_get_two" type="submit">
                                Subscribe
                            </button>
                            <p className="mchimp-errmessage" style={{ display: "none" }} />
                            <p className="mchimp-sucmessage" style={{ display: "none" }} />
                            </form> */}
                        </div>
                        </div>
                        {/* <div className="col-lg-3 col-md-6"> */}
                        {/* <div
                            className="f_widget about-widget pl_70 wow fadeInLeft"
                            data-wow-delay="0.4s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.4s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            <h3 className="f-title f_600 t_color f_size_18">Download</h3>
                            <ul className="list-unstyled f_list">
                            <li>
                                <a href="#">Company</a>
                            </li>
                            <li>
                                <a href="#">Android App</a>
                            </li>
                            <li>
                                <a href="#">ios App</a>
                            </li>
                            <li>
                                <a href="#">Desktop</a>
                            </li>
                            <li>
                                <a href="#">Projects</a>
                            </li>
                            <li>
                                <a href="#">My tasks</a>
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                        <div
                            className="f_widget about-widget pl_70 wow fadeInLeft"
                            data-wow-delay="0.6s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.6s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                            <ul className="list-unstyled f_list">
                            <li>
                                <a href="#">FAQ</a>
                            </li>
                            <li>
                                <a href="#">Term &amp; conditions</a>
                            </li>
                            <li>
                                <a href="#">Reporting</a>
                            </li>
                            <li>
                                <a href="#">Documentation</a>
                            </li>
                            <li>
                                <a href="#">Support Policy</a>
                            </li>
                            <li>
                                <a href="#">Privacy</a>
                            </li>
                            </ul>
                        </div> */}
                        {/* </div> */}
                        <div className="col-lg-3 col-md-6">
                        <div
                            className="f_widget social-widget pl_70 wow fadeInLeft"
                            data-wow-delay="0.8s"
                            style={{
                            visibility: "visible",
                            animationDelay: "0.8s",
                            animationName: "fadeInLeft"
                            }}
                        >
                            <h3 className="f-title f_600 t_color f_size_18">Follow Us!</h3>
                            <div className="f_social_icon" style={{marginTop:"-7%"}}>
                            <a href="#" className="fab fa-facebook" />
                            <a href="#" className="fab fa-twitter" />
                            <a href="#" className="fab fa-linkedin" />
                            <a href="#" className="fab fa-pinterest" />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    {/* <div className="footer_bg">
                    <div className="footer_bg_one" />
                    <div className="footer_bg_two" />
                    </div> */}
                </div>
                {/* <div className="footer_bottom">
                    <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-7">
                        <p className="mb-0 f_400">
                            © cakecounter Inc.. 2019 All rights reserved.
                        </p>
                        </div>
                        <div className="col-lg-6 col-sm-5 text-right">
                        <p>
                            Made with <i className="icon_heart" /> in{" "}
                            <a href="http://cakecounter.com" target="_blank">
                            CakeCounter
                            </a>
                        </p>
                        </div>
                    </div>
                    </div>
                </div> */}
                </footer>

        </>
    )
}

export default FooterMenu