import {React, useState} from 'react'
import Header from './Header'
import FooterMenu from './FooterMenu'
import Footer from './Footer'
import { Helmet } from 'react-helmet'

function Privacy() {
    

    return(
        <>
        <Header dark={false}/>
            <Helmet>
                <title>Privacy Policy</title>
                <meta name="description" content="Your privacy plays an important role for Welcome Tours and Travels Chennai Pvt Ltd " />
                <meta name="keywords" content="Privacy Policy" />
                <meta name="robots" content="index, nofollow"/>
            </Helmet>
            <div style={{height:"25vh", width:"100%", backgroundColor:"#12675e", color:"white", display:"flex", justifyContent:"center", alignContent:"center", textAlign:"centers"}}>
                <div style={{justifyContent:"center", alignContent:"center", textAlign:"center", paddingTop:"5vh", fontFamily:"Lato", fontWeight:"900", fontSize:"20px"}}>
                    Privacy Policy
                </div>
            </div>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center", textAlign:"justify", paddingTop:"5vh", fontFamily:"Lato", fontWeight:"600", fontSize:"20px", padding:"5% 10% 10% 10%"}}>
                <p>Your privacy plays an important role for Welcome Tours and Travels Chennai Pvt Ltd . We believe that customers have the right to know about the privacy policy followed by us. Welcome Tours and Travels Chennai Pvt Ltd understands the value of information that is being collected, used, disclosed and stored.</p>

                <p>The idea of this privacy policy is to educate the customers of what personal information is captured and used by us. We also assure that your information will never be shared with any of the third parties or at the time of registration.</p>

                <h3>Collection of Personal Information</h3>

                <p>Personal information is a valid data that is used to identify a particular person and contact them. Welcome Tours and Travels Chennai Pvt Ltd requests its customers to share their personal information directly or indirectly through a third party. However, we do not collect any type of information without your permission or knowledge. Our Welcome Tours and Travels Chennai Pvt Ltd- Team takes reasonable steps to protect the security of your information we collect from you.</p>

                <h3>Customers will generally share their personal information in the following circumstances:</h3>

                <p>*  When you book your tickets or any holiday package through our website, then you will be asked for information like email, letter, fax or so on.</p>

                <p>*  At the time of registration, the customer service team will request you to participate in competitions or subscribe for promotional newsletters.</p>

                <p>*  When you take part in surveys, you will be directed to a link asking for personal details. Your privacy is 100% protected by us.</p>

                <h3>Use of Personal Information</h3>

                <p>We use the personal information of the users in a beneficial way to both of us. The collected information is used and transferred to the third parties for booking, enquiry and transaction process. You should know that the information gathered from you will be processed outside India as we need to arrange the products and services requested by you. Customers will be requested to confirm the acceptance of sharing of information at the time of booking.</p>

                <p>The personal information collected from you will be used to send Welcome Tours and Travels Chennai Pvt Ltd latest product announcements, promotions, offers and events. We are glad to inform that it helps us to improve our products and offerings to a great extent. Customers have the option to unsubscribe the announcements at any point of time.</p>

                <p>We mainly use your information to upgrade our current products, services, content and advertising. Sometimes, the collected personal information will be used internally for research, analysis and auditing.</p>

                <h3>Use of Non- Personal Information</h3>

                <p>Non-personal information means the information that does not identify a particular user. The list of non-personal information include browser, operating system, occupation, language, location, pin code and device identifier. Welcome Tours and Travels Chennai Pvt Ltd collects this type of information to enhance the user experience as well the products and services.</p>

                <h3>Use of Cookies and Technologies</h3>

                <p>Cookies play an important role in offering the best user experience for all our valuable Welcome Tours and Travels Chennai Pvt Ltd customers. Most of the web browsers accept cookies while others require some changes to be done in your web browser. However, the users can easily control and delete the required cookies immediately. We make use of cookies to upgrade our online services, applications, advertisements and email messages. Generally, the cookies are used to identify certain information like passwords, list of pages visited, identify your session at a particular website and so on.</p>

                <p>Welcome Tours and Travels Chennai Pvt Ltd assure that we do not use cookies for identifying your personal information or share your privacy with any of the third parties.</p>

                <h3>Disclosure to Third Parties</h3>

                <p>Welcome Tours and Travels Chennai Pvt Ltd has the right to share the personal information as required with any of the third parties. It is mainly shared on matters relating to booking, reservation, blocking or any such activity initiated by users.</p>

                <h3>Service Providers and Partners</h3>

                <p>Welcome Tours and Travels Chennai Pvt Ltd may disclose the personal information with the companies who offers services like information processing, developing customer data, conducting customer research and satisfaction surveys.</p>

                <h3>With Whom Your Personal Information is Shared?</h3>

                <p>We usually share anonymous information and reports to our reputed suppliers, advertisers or any of the potential business partners. Welcome Tours and Travels Chennai Pvt Ltd-requests all the customers to read over the privacy policy before their booking process. This is because they can gain more information about us in a detailed manner.</p>

                <h3>Changes to this Privacy Policy</h3>
                <p>Welcome Tours and Travels Chennai Pvt Ltd reserves the right to change the policy at any time. Users are required to change the page periodically as we are not responsible if users don’t read over our policy. However, we will definitely notify the changes made in the privacy policy to all our customers through email or post.</p>

                <p>If you have any questions or queries, please feel free to contact us any time.</p>

                <p>We are eager to hear your comments regarding our privacy policy. For more details, contact us at director@allindiatours.com and we will respond to your need immediately.</p>
            </div>
            <FooterMenu/>
            <Footer/>
        </>
    )
}

export default Privacy