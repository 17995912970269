
import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import mapboxSdk from '@mapbox/mapbox-sdk/services/directions';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const MapWithDirections = ({ coordinates }) => {
    const [map, setMap] = useState(null);

    useEffect(() => {
    mapboxgl.accessToken = 'pk.eyJ1Ijoicml0aGlrY2hvcHJhIiwiYSI6ImNsc3VhZDZuZzFjMmEya3FrOGltazFiN2gifQ.UxjCAifZ2vcRC2YUJT52uA';

    const initializeMap = ({ setMap, mapContainer }) => {
        const mapInstance = new mapboxgl.Map({
        container: mapContainer,
        style: 'mapbox://styles/mapbox/streets-v11', // You can change the map style here
        center: coordinates[0], // Set the initial center of the map
        zoom: 10, // Set the initial zoom level of the map
        });

        mapInstance.on('load', () => {
        setMap(mapInstance);
        });

        // Add navigation control (optional)
        mapInstance.addControl(new mapboxgl.NavigationControl());
    };

    if (!map) initializeMap({ setMap, mapContainer: 'map' });

    return () => map && map.remove();
    }, [map, coordinates]);

    useEffect(() => {
    if (map) {
        const directionsClient = mapboxSdk({ accessToken: mapboxgl.accessToken });
        const waypoints = coordinates.map(coord => ({ coordinates: coord }));

        directionsClient
        .getDirections({
            // profile: 'driving',
            waypoints,
            geometries: 'polyline6',
            language: 'en',
            overview: 'full',
            steps: true,
            alternatives: true,
        })
        .send()
        .then(response => {
            const routes = response.body.routes;
            if (routes.length > 0) {
            const route = routes[0]; // You can choose a different route if there are alternatives
            const decodedRoute = mapboxgl.LngLat.convertLengths(route.geometry.coordinates, 1, 'kilometers');
            map.addLayer({
                id: 'route',
                type: 'line',
                source: {
                type: 'geojson',
                data: {
                    type: 'Feature',
                    properties: {},
                    geometry: {
                    type: 'LineString',
                    coordinates: decodedRoute,
                    },
                },
                },
                layout: {
                'line-join': 'round',
                'line-cap': 'round',
                },
                paint: {
                'line-color': '#3887be',
                'line-width': 5,
                'line-opacity': 0.75,
                },
            });
            }
        })
        .catch(error => console.error('Error fetching directions:', error));
    }
    }, [map, coordinates]);

    return <div id="map" style={{ width: '100%', height: '400px', position:"relative" }} />;
};

export default MapWithDirections;
    
