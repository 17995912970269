import SearchBannerImg from '../assets/searchHeader.png.avif'
import {React, useState} from 'react'

function SearchBanner({location, img}) {
    const initialHeight = 100; // Set the initial height for the content
    const [expanded, setExpanded] = useState(false);
    const [contentHeight, setContentHeight] = useState(initialHeight);

    const toggleReadMore = () => {
        setExpanded(!expanded);
        setContentHeight(expanded ? initialHeight : null);
    };

    return(
        <>
            <div style={{minWidth:"100%", position:"relative", display:"block", overflowX:"hidden", fontFamily:"Lato"}}>
                <div style={{top:"0", left:"0", width:"100%" }}>
                    <img style={{minHeight:"500px",minWidth:"100%", maxWidth:"100%", minHeight:"350px",maxHeight:"350px", objectFit:"cover"}} src={img}/>
                    <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.1)", pointerEvents:"none"}}></div>
                </div>
                <div style={{position:"absolute", top:"50%", left:"50%", transform:"translate(-50%, -50%)", color:"white", textAlign:"center", minWidth:"90%"}}>
                    <div style={{fontSize:"30px", fontWeight:"bolder", fontFamily:"Maven Pro", letterSpacing:"1px", textTransform:"uppercase"}}>
                        Discover our {location} Tours
                    </div>
                    {/* <div style={{paddingTop:"20px", fontWeight:"400", fontWeight:"500"}}>
                        Land Down Under is now fully open for International Travellers
                    </div> */}
                    <div style={{paddingTop:"20px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <div style={{background:"#2acc65", color:"white", padding:"10px 20px", fontSize:"18px", borderRadius:"30px", textTransform:"capitalize", display:"flex", alignItems:"center", justifyContent:"center", maxWidth:"60%"}}>
                            SEE TRIPS
                            {/* <svg style={{fill:"white", maxWidth:"20px", paddingLeft:"2%"}} viewBox="0 0 24 24" fill="grey"><path fill-rule="evenodd" d="M3 12a9 9 0 1018 0 9 9 0 00-18 0zm9 11C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm-1-9.414l-2.293-2.293a1 1 0 00-1.414 1.414l4 4 .007.007c.18.176.425.285.697.286h.006a.996.996 0 00.697-.286l.008-.008 4-3.999a1 1 0 00-1.415-1.414L13 13.586V8a1 1 0 10-2 0v5.586z" clip-rule="evenodd"></path></svg> */}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{minWidth:"100%", backgroundColor:"white"}}>
                <div className='search-page-explore-with-WT' style={{textAlign:"center"}}>
                    <div className='font-size-32-20' style={{fontFamily:"Lato", fontWeight:"900"}}>
                        Explore the World with Welcome Tours: Our Exclusive Tour Packages
                    </div>
                    <div className='font-size-18-14 padding-2-1' style={{color:"black", fontFamily:"Lato", fontWeight:"400"}}>
                        <div
                            className="read-more-content"
                            style={{
                            maxHeight: contentHeight,
                            overflow: 'hidden',
                            transition: 'max-height 0.3s ease',
                            }}
                        >
                            <div>
                                Embark on unforgettable journeys with Welcome Tours. Discover a world of enchanting destinations and immersive experiences through our meticulously crafted tour packages. From breathtaking landscapes to vibrant cultures, our curated collection of tours promises adventure, relaxation, and cultural richness. Choose your next getaway and let Welcome Tours be your guide to extraordinary travel moments. Book now and make memories that last a lifetime. Welcome to a world of endless exploration!
                            </div>
                        </div>
                        <div onClick={toggleReadMore} style={{fontFamily:"Maven Pro", textDecoration:"underline", cursor:"pointer", fontSize:"20px"}}>
                            {expanded ? 'Read Less' : 'Read More'}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchBanner