import React from "react";
import '../styles/Homepage.scss'

function TripHighlights ({packageData}){
    {packageData.trip_highlights.map((data) => {
        console.log(process.env.PUBLIC_URL + data.img); // Log the path
    })}      
    return(
        <>
            <div className="add-margin-btm" style={{paddingTop:"5%", backgroundColor:"white"}}> 
                    <h2 className="font-size-36-20" style={{textAlign:"center", paddingLeft:"0 5%", color:"#3f4144", textTransform:"uppercase", fontWeight:"900", fontFamily:"Lato"}}>
                        Trip Highlights
                    </h2>
                    <p style={{textAlign:"center", fontSize:"16px", margin:"0 5%", fontFamily:"Lato", fontWeight:"400"}}>The must do experiences that you can cross off your bucket list</p>
                <div className="trip-highlight-section" style={{whiteSpace: "nowrap",overflowX: "auto", display:"flex", flexDirection:"row"}}>
                    {packageData.trip_highlights.map((data) => (
                        <div className="page3-trip-highlings-img-sections">
                            <div className="height-img-define" style={{position:"relative"}}>
                                <div className="page3-inset page3-highlight-img" style={{position: "absolute", borderRadius: "6px", backgroundColor: "#12765e"}}></div>
                                <img className="shadow-on-hover page3-highlight-img" src={process.env.PUBLIC_URL + data.img} style={{borderRadius:"5px", marginBottom:"5%", objectFit:"fill", display: "inline-block",marginLeft: "7%", position:"relative", zIndex:"1"}} />
                            </div>
                            <div className="trip-highlight-text" style={{textAlign:"left"}}>
                                <div className="font-size-22-18" style={{fontWeight:"900", fontFamily:"Lato"}}>{data.title}</div>
                                <div style={{fontSize:"14px", fontWeight:"bold", paddingTop:"3%", whiteSpace:"pre-line", fontWeight:"400", fontFamily:"Lato"}}>{data.description}</div>
                                <div className="font-size-20-14" style={{fontWeight:"900", paddingTop:"3%", fontFamily:"Lato"}}>{data.included_or_not}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}

export default TripHighlights