import {React, useState, useEffect} from "react";
import Skeleton from './Skeleton';
import '../styles/Homepage.scss'

function HomepageSlider({ dataSlider, title, subtitle }) {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true); // Set loading state to true when component mounts or dataSlider changes
    }, [dataSlider]);

    const loadImage = (data) => { // Pass 'data' as a parameter to the loadImage function
        const img = new Image();
        img.src = process.env.PUBLIC_URL + data.img;
        img.onload = () => {
            console.log('Image is loaded');
            setLoading(false);
        };
    };

    useEffect(() => {
        console.log('Component is mounted or dataSlider changed');
        dataSlider.forEach(data => loadImage(data)); // Load images for each 'data' item in 'dataSlider'
    }, [dataSlider]);

    return (
        <>
            <div className="homepage-slider-section">
                {title && 
                    <h2 style={{ textAlign: "left", color: "#3f4144", textTransform: "uppercase", fontFamily: "Lato", fontWeight: "700", paddingTop: "1vh" }} className="font-size-32-20 home-slider-h2">
                        {title}
                    </h2>
                }
                {subtitle &&
                    <p className="home-slider-h2" style={{ textAlign: "left", color: "grey", fontSize: "18px", paddingBottom: "0.5vh" }}>{subtitle}</p>
                }
                <div style={{ width: "100%", whiteSpace: "nowrap", overflowX: "auto", display: "flex", flexDirection: "row" }} className="homepage-img-section">
                    {loading ? ( // Render skeleton while loading
                        dataSlider.map((data, index) => (
                            <Skeleton key={index} /> // Adjust skeleton component as per your design
                        ))
                    ) : (
                        dataSlider.map((data, index) => (
                            <a key={index} style={{ position: "relative" }} href={`/search/${data.title}`}>
                                <img  className="homepage-slider-img shadow-on-hover" src={data.img} alt={data.title}/>
                                {/* <div style={{ fontFamily: "GoldenDragon", position: "absolute", top: "80%", left: "50%", transform: "translateX(-57%)", color: "white", fontWeight: "900", fontSize: "22px", lineHeight: "1em", textAlign: "left" }}>{data.title}<br /> */}
                                {/* </div>  */}
                                <div className="font-size-24-18 homepage-slider-padding" style={{ fontWeight: "bolder", textAlign: "center", marginRight: "4%", paddingTop: "2%" }}>{data.title}</div>
                            </a>
                        ))
                    )}
                </div>
            </div>
        </>
    );
}

export default HomepageSlider