import React from "react";
import '../styles/Homepage.scss'

function HomepageSliderCreatives ({dataSlider, title, subtitle}){
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
      }
      dataSlider=shuffleArray(dataSlider)
    return(
        <>
            <div className="homepage-slider-section">
                {title && 
                    <h2 style={{textAlign:"left", color:"#3f4144", textTransform:"uppercase", fontFamily:"Lato", fontWeight:"700"}} className="font-size-32-20 home-slider-h2">
                        {title}
                    </h2>
                }
                {subtitle &&
                    <p style={{textAlign:"center", color:"grey", fontSize:"18px"}}>{subtitle}</p>
                }
                    <div style={{width: "100%",whiteSpace: "nowrap",overflowX: "auto", display:"flex", flexDirection:"row"}} className="homepage-img-section">
                        {dataSlider.map((data ,index) => (
                            <a style={{position:"relative"}} href={`/search/${data.title}`}> 
                                <img className="homepage-slider-img shadow-on-hover object-fit-contain-mobile" src={data.img} style={{minHeight:"285px", minWidth:"265px", maxWidth:"265px", maxHeight:"285px", borderRadius:"0"}} />
                                {/* <div style={{fontFamily:"GoldenDragon", position:"absolute", top:"80%",left:"50%",transform: "translateX(-57%)", color:"white", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data.title}<br/> 
                                </div>  */}
                                <div className="font-size-24-18 homepage-slider-padding" style={{fontWeight:"bolder", textAlign:"center", marginRight:"4%", paddingTop:"2%"}}>{data.title}</div>
                            </a>
                        ))}
                    </div>
            </div>
        </>
    )
}

export default HomepageSliderCreatives