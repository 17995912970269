import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Homepage.scss'

const ImageCarousel = ({ images }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <ArrowButton direction="left" />,
    nextArrow: <ArrowButton direction="right" />
  };

  return (
    <Slider {...settings}>
      {images.map((image, index) => (
        <div key={index}>
          <img className='search-card-img' src={image} alt={`Image ${index + 1}`} />
        </div>
      ))}
    </Slider>
  );
};

const ArrowButton = ({ direction, onClick }) => (
  <button
    className={`carousel-arrow ${direction}`}
    onClick={onClick}
  >
    {direction === 'left' ? '<' : '>'}
  </button>
);

export default ImageCarousel;

