import {React} from 'react'
import { Helmet } from 'react-helmet'

function NotFoundPage() {

    return(
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow"/>
            </Helmet>
            <h1>404 - Page not found</h1>
            <p>The page you're looking for doesn't exist.</p>
        </>
    )
}

export default NotFoundPage