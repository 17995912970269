import {React, useState} from 'react'
import Header from './Header'
import FooterMenu from './FooterMenu'
import Footer from './Footer'
import FAQ from './FAQ'
import { Helmet } from 'react-helmet'

function FAQPage() {
    

    return(
        <>
        <Header dark={false}/>
            <Helmet>
                <title>Frequently Asked Questions</title>
                <meta name="description" content="What does Welcome Tours include?" />
                <meta name="keywords" content="Frequently Asked Questions" />
                <meta name="robots" content="index, nofollow"/>
            </Helmet>
            <div style={{height:"25vh", width:"100%", backgroundColor:"#12675e", color:"white", display:"flex", justifyContent:"center", alignContent:"center", textAlign:"centers"}}>
                <div style={{justifyContent:"center", alignContent:"center", textAlign:"center", paddingTop:"5vh", fontFamily:"Lato", fontWeight:"900", fontSize:"20px"}}>
                    FAQ
                </div>
            </div>
            <FAQ/>
            <FooterMenu/>
            <Footer/>
        </>
    )
}

export default FAQPage