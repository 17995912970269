import React from "react";
import '../styles/Homepage.scss'

function HomepageBackgroundColorSectionWeb({title, description, data}){
    return(
        // <div className="display-web" style={{backgroundColor:"#121b22", padding:"5% 0 5% 0", marginTop:"5%"}}>
        <div className="display-web" style={{backgroundColor:"#121b22", display:"flex", alignContent:"center", alignItems:"center", padding:"3% 0 5% 0", flexDirection:"column", marginTop:"5%"}}>
            <h2 style={{textAlign:"center", color:"#2acc65", fontWeight:"700"}}>
                {title}
            </h2>
            <p style={{textAlign:"center", color:"#2acc65", fontSize:"18px"}}>{description}</p>
            <div style={{display:"flex",justifyContent:"center", alignContent:'center', margin:"0 15%", minHeight:"45vh", marginTop:"3%"}}>
                <div style={{ whiteSpace: "normal", textAlign: "center", display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                    {/* {data.map((data, index) => (
                        <div key={index} style={{ flex: "1 0 30%", margin: "5px" }}>
                        <div>
                            <img src={data.img} className="img-fluid bg-img bg-color-imgs" style={{ borderRadius: "20px", objectFit: "cover", display: "block", margin: "auto" }} />
                            <div style={{ paddingTop: "3%", color: "#2acc65", textTransform: "capitalize", textAlign: "center" }}>{data.title}</div>
                        </div>
                        </div>
                    ))} */}
                    <div style={{minWidth:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignContent:"space-between", maxHeight:"350px", minWidth:"66vw", maxWidth:"80vw", marginTop:"2%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", minWidth:"32%", maxWidth:"32%"}}>
                            <div>
                                <div style={{top:"0", left:"0", position:"relative" }}>
                                    <a className="image-smudge-effect" href={`/search/${data[0].title}`}>
                                        <img src={data[0].img} style={{minWidth:"100%", maxWidth:"100%",minHeight:"190px", maxHeight:"190px", borderRadius: "20px", objectFit: "cover", marginBottom:"20px" }} />
                                        {/* <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div> */}
                                        <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[0].title}<br/> 
                                            {/* ZZAA */}
                                            {/* <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[0].price}</span> */}
                                            <span style={{fontWeight:"400", fontSize:"15px"}}>From ₹{data[0].price}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                    <a className="image-smudge-effect" href={`/search/${data[1].title}`}>
                                        <img src={data[1].img} style={{maxWidth:"175px", minWidth:"175px", minHeight:"190px", maxHeight:"190px", borderRadius: "20px", objectFit: "cover" }} />
                                        {/* <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div> */}
                                        <div style={{position:"absolute", top:"56%", left:"10%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[1].title}<br/> 
                                            {/* ZZAA */}
                                            {/* <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[1].price}</span> */}
                                            <span style={{fontWeight:"400", fontSize:"15px"}}>From ₹{data[1].price}</span>
                                        </div>
                                    </a>
                                </div>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative", }}>
                                    <a className="image-smudge-effect" href={`/search/${data[2].title}`}>
                                        <img src={data[2].img} style={{maxWidth:"175px", minWidth:"175px", minHeight:"190px", maxHeight:"190px", borderRadius: "20px", objectFit: "cover" }} />
                                        {/* <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div> */}
                                        <div style={{position:"absolute", top:"56%", left:"10%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[2].title}<br/> 
                                        {/* ZZAA */}
                                            {/* <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[2].price}</span> */}
                                            <span style={{fontWeight:"400", fontSize:"15px"}}>From ₹{data[2].price}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column"}}>
                            <div>
                                <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                    <a className="image-smudge-effect" href={`/search/${data[3].title}`}>
                                        <img src={data[3].img} style={{minWidth:"370px", maxWidth:"370px",minHeight:"407px", maxHeight:"407px", borderRadius: "20px", objectFit: "cover", margin:"0 30px 0 30px" }} />
                                        {/* <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div> */}
                                        <div style={{position:"absolute", top:"77%", left:"10%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[3].title}<br/> 
                                        {/* ZZAA */}
                                            {/* <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[3].price}</span> */}
                                            <span style={{fontWeight:"400", fontSize:"15px"}}>From ₹{data[3].price}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
                            <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                <a className="image-smudge-effect" href={`/search/${data[4].title}`}>
                                    <img src={data[4].img} style={{minWidth:"370px", maxWidth:"370px",minHeight:"190px", maxHeight:"190px", borderRadius: "20px", objectFit: "cover",  marginBottom:"20px" }} />
                                    {/* <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div> */}
                                        <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[4].title}<br/> 
                                        {/* ZZAA */}
                                            {/* <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[4].price}</span> */}
                                            <span style={{fontWeight:"400", fontSize:"15px"}}>From ₹{data[4].price}</span>
                                        </div>
                                    </a>
                                </div>
                            <div style={{top:"0", left:"0", width:"100%", position:"relative" }}>
                                    <a className="image-smudge-effect" href={`/search/${data[5].title}`}>
                                        <img src={data[5].img} style={{minWidth:"370px", maxWidth:"370px",minHeight:"190px", maxHeight:"190px", borderRadius: "20px", objectFit: "cover" }} />
                                        {/* <div style={{position:"absolute", top:"0", left:"0", width:"100%", height:"100%", background:"rgba(0, 0, 0, 0.4)", pointerEvents:"none", borderRadius: "20px"}}></div> */}
                                            <div style={{position:"absolute", top:"56%", left:"5%", color:"white", fontFamily:"Lato", fontWeight:"900", fontSize:"22px", lineHeight:"1em", textAlign:"left"}}>{data[5].title}<br/> 
                                            {/* ZZAA */}
                                                {/* <span style={{fontWeight:"400", fontSize:"15px"}}>From ${data[5].price}</span> */}
                                                <span style={{fontWeight:"400", fontSize:"15px"}}>From ₹{data[5].price}</span>
                                            </div>
                                    </a>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepageBackgroundColorSectionWeb