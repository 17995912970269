import React from "react";

function HomepageVacationForTravellers({title, description, data}){
    return(
        <div className="travellers-border" style={{backgroundImage:"radial-gradient(50.87% 50.87% at 49.13% 49.65%, transparent 0px, rgba(0, 0, 0, 0.48) 100%)", backgroundColor:"#3647ac", padding:"5% 10px", lineHeight:"12px"}}>
            <h2 style={{textAlign:"center", color:"white", fontWeight:"700"}}>
                {title}
            </h2>
            <p style={{textAlign:"center", color:"#24b774", fontFamily:"Dancing Script", fontSize:"28px"}}>{description}</p>
            <div style={{display:"flex", justifyContent:"center", alignContent:"center"}}>
                <div className="bg-img-width-travller" style={{ whiteSpace: "normal", textAlign: "center", display: "flex", justifyContent: "space-around", padding: "20px" }}>
                    {data.map((data, index) => (
                        <div className="flex-30-20" key={index} style={{ margin: "15px" }}>
                        <div className="travellers-img-home" style={{ position: "relative" }}>
                            <a style={{display:'flex',justifyContent:"center", alignContent:"center"}} href={`/search/${data.title}`}>
                            <img src={data.img} className="img-fluid bg-img travellers-img-home" style={{ borderRadius: "20px", objectFit: "cover", display: "block", margin: "0 10px" }} />
                            <div style={{ display: "flex", textAlign: "center", justifyContent: "center", alignContent: "center", position: "absolute", top: "90%", left: "50%", fontSize: "12px", wordWrap: "normal", transform: "translate(-50%, -50%)", background: "#24b774", width: "100%", height: "20%", borderRadius: "0 0 20px 20px", display: "flex", alignItems: "center", justifyContent: "center", color: "white", textTransform: "capitalize" }}>{data.title}</div>
                            </a>
                        </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default HomepageVacationForTravellers