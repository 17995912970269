import React from "react";

function Footer(){

    return(
        <div>
            <div className="display-mobile">
                <div style={{position:"fixed", bottom:"0", width:"100%", height:"7vh", background:"#fff", display:"flex", flexDirection:"row" , justifyContent:"space-between", fontSize:"14px", zIndex:"99999"}}>
                    <a href="/" style={{width:"30%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 12 14" cursor="pointer" ><path fill-rule="evenodd" clip-rule="evenodd" d="M7.682.985a2.495 2.495 0 00-3.364 0l-3.5 3.19A2.51 2.51 0 000 6.032v5.129a2.503 2.503 0 002.5 2.507h7c1.38 0 2.5-1.123 2.5-2.507V6.03a2.51 2.51 0 00-.818-1.854l-3.5-3.19zM8 9.667v2.667H4V9.667a2 2 0 114 0z"></path></svg>
                        Explore
                    </a>
                    {/* ZZAA */}
                    {/* <a href="https://wa.me/+27837789189?text=Hello%20there!" style={{width:"30%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}> */}
                    <a href="https://wa.me/+919840339933?text=Hello%20there!" style={{width:"30%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-whatsapp" viewBox="0 0 16 16"> <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/> </svg>
                        WhatsApp
                    </a>
                    {/* ZZAA */}
                    {/* <a herf="tel:+27837789189" style={{width:"30%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"rgb(152, 255, 83)", background:"#000"}}> */}
                    <a href="tel:+919840339933" style={{width:"30%", display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", color:"rgb(152, 255, 83)", background:"#000"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="#98FF53" cursor="pointer"><path fill-rule="evenodd" clip-rule="evenodd" d="M2.87794 1.66493C3.49847 1.0444 4.51683 1.08676 5.08368 1.75667L6.66028 3.61993C7.08155 4.11779 7.13285 4.8308 6.7872 5.38384L6.22848 6.27781C6.13191 6.4323 6.13864 6.59902 6.21525 6.71546C6.73199 7.50088 7.92432 9.03832 9.73616 9.85344C9.8513 9.90525 10.005 9.89575 10.1436 9.80335L10.6679 9.45377C11.1718 9.11787 11.8282 9.11787 12.332 9.45377L14.1522 10.6672C14.7191 11.0452 14.9159 11.7862 14.6112 12.3956C13.8324 13.9533 12.0512 14.8965 10.3238 14.279C6.19257 12.8022 3.24323 9.97787 1.60711 6.41249C0.896583 4.86412 1.41795 3.12492 2.5586 1.98427L2.87794 1.66493ZM4.3203 2.40262C4.13135 2.17931 3.79189 2.16519 3.58505 2.37204L3.26571 2.69138C2.35419 3.6029 2.01609 4.90605 2.51599 5.99541C4.03969 9.31583 6.78261 11.9512 10.6604 13.3374C11.8265 13.7542 13.1214 13.1392 13.7167 11.9484C13.7959 11.79 13.7448 11.5975 13.5975 11.4992L11.7773 10.2858C11.6094 10.1739 11.3906 10.1739 11.2226 10.2858L10.6983 10.6354C10.3076 10.8959 9.79088 10.9746 9.32588 10.7654C7.25765 9.83493 5.93593 8.11032 5.37984 7.26509C5.06539 6.78715 5.09995 6.19666 5.38048 5.74781L5.9392 4.85384C6.05442 4.6695 6.03732 4.43183 5.8969 4.26587L4.3203 2.40262Z"></path></svg>
                        Call Us!
                    </a>
                </div>
            </div>
            <div id="fixed-social">
                <div class="social-menu">
                    <ul>
                        <li><a href="/" target="blank"><i class="fa-solid fa-home"></i></a></li>
                        {/* ZZAA */}
                        <li><a href="https://wa.me/+919840339933?text=Hello%20there!" target="blank"><i class="fab fa-whatsapp"></i></a></li>
                        {/* <li><a href="https://wa.me/+27837789189?text=Hello%20there!" target="blank"><i class="fab fa-whatsapp"></i></a></li> */}
                        {/* ZZAA */}
                        <li><a href="tel:+919840339933" target="blank"><i class="fa-solid fa-phone"></i></a></li>
                        {/* <li><a href="tel:+27837789189" target="blank"><i class="fa-solid fa-phone"></i></a></li> */}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer