import  {React, useState, useEffect } from 'react'
// ZZAA
import logoPng from '../assets/logo1.png.avif'
import darkPng from '../assets/Horizontal Logo.png.avif'
// import logoPng from '../assets/Horizontal L.png'
// import darkPng from '../assets/Horizontal L.png'
import $ from 'jquery'
import Menu from './Menu';
import { northIndiaData, southIndiaData, ScandinaviaData, oceaniaData, VisaArrivalData } from './Homepage.jsx';


function Header({dark = false}) {
    const menuData = [
        {category:"North India Tours", subcategories:northIndiaData.map((data) => (data.title ))}, 
        {category:"South India Tours", subcategories: southIndiaData.map((data) => (data.title ))},
        {category:"Visa on Arrival Destinations", subcategories: VisaArrivalData.map((data) => (data.title ))},
        {category:"Scandinavia Destinations", subcategories: ScandinaviaData.map((data) => (data.title ))},
        {category:"Oceania Destinations", subcategories: oceaniaData.map((data) => (data.title ))}
    ]
    // const menuData = [
    //     {
    //         category: "International Holidays",
    //         subcategories: [
    //             "Maldives Packages",
    //             "Bali Packages",
    //             "Thailand Packages",
    //             "Dubai Packages",
    //             "Singapore Packages",
    //             "Vietnam Packages",
    //             "Europe Packages",
    //             "Australia Packages",
    //             "New Zealand Packages",
    //             "Sri Lanka Packages",
    //             "Mauritius Packages",
    //             "South Africa Packages",
    //             "Greece Packages",
    //             "Turkey Packages",
    //             "UK Packages",
    //             "France Packages",
    //             "Switzerland Packages",
    //             "Italy Packages",
    //             "Spain Packages"
    //         ]
    //     },
    //     {
    //         category: "Honeymoon Getaways",
    //         subcategories: [
    //             "Maldives Honeymoon Packages",
    //             "Bali Honeymoon Packages",
    //             "Thailand Honeymoon Packages",
    //             "Dubai Honeymoon Packages",
    //             "Singapore Honeymoon Packages",
    //             "Vietnam Honeymoon Packages",
    //             "Europe Honeymoon Packages",
    //             "Australia Honeymoon Packages",
    //             "New Zealand Honeymoon Packages",
    //             "Sri Lanka Honeymoon Packages",
    //             "Mauritius Honeymoon Packages",
    //             "South Africa Honeymoon Packages",
    //             "Greece Honeymoon Packages",
    //             "Turkey Honeymoon Packages",
    //             "UK Honeymoon Packages",
    //             "France Honeymoon Packages",
    //             "Switzerland Honeymoon Packages",
    //             "Italy Honeymoon Packages",
    //             "Spain Honeymoon Packages"
    //         ]
    //     },
    //     {
    //         category: "Honeymoon Getaways",
    //         subcategories: [
    //             "Maldives Honeymoon Packages",
    //             "Bali Honeymoon Packages",
    //             "Thailand Honeymoon Packages",
    //             "Dubai Honeymoon Packages",
    //             "Singapore Honeymoon Packages",
    //             "Vietnam Honeymoon Packages",
    //             "Europe Honeymoon Packages",
    //             "Australia Honeymoon Packages",
    //             "New Zealand Honeymoon Packages",
    //             "Sri Lanka Honeymoon Packages",
    //             "Mauritius Honeymoon Packages",
    //             "South Africa Honeymoon Packages",
    //             "Greece Honeymoon Packages",
    //             "Turkey Honeymoon Packages",
    //             "UK Honeymoon Packages",
    //             "France Honeymoon Packages",
    //             "Switzerland Honeymoon Packages",
    //             "Italy Honeymoon Packages",
    //             "Spain Honeymoon Packages"
    //         ]
    //     },
    //     {
    //         category: "Packages by City",
    //         subcategories: [
    //             "Packages from Chennai",
    //             "Packages from Mumbai",
    //             "Packages from Delhi",
    //             "Packages from Bangalore",
    //             "Packages from Pune",
    //             "Packages from Ahmedabad",
    //             "Packages from Kolkata",
    //             "Packages from Kochi",
    //             "Packages from Hyderabad"
    //         ]
    //     },
    //     {
    //         category: "Packages by Interest",
    //         subcategories: [
    //             "International Tour Packages",
    //             "International Honeymoon Packages",
    //             "Family Packages",
    //             "Luxury Packages",
    //             "Budget Packages",
    //             "International Packages under 30K",
    //             "International Packages under 40K",
    //             "International Packages under 50K",
    //             "International Packages under 75K",
    //             "International Packages under 3lakhs",
    //             "International Honeymoon under 50K",
    //             "International Honeymoon under 1lakh",
    //             "International Honeymoon under 2lakhs",
    //         ]
    //     },
    // ];

    const logoSrc = dark ? darkPng : logoPng;
    const burgerIcon = dark ? 'black' : 'white';
        const [showSidebar, setShowSidebar] = useState(false);

        const toggleSidebar = () => {
            setShowSidebar(!showSidebar);
        };

        useEffect(() => {
            $(".open").click(function () {
                var container = $(this).parents(".topic");
                var answer = container.find(".answer");
                var trigger = container.find(".faq-t");
    
                answer.slideToggle(300); // Adjust the duration as needed
                container.toggleClass("expanded");
    
                trigger.toggleClass("faq-o");
            });
        },[]);
        
        const [activeDropdownIndex, setActiveDropdownIndex] = useState(null);

    const handleDropdownToggle = (index) => {
        setActiveDropdownIndex(index);
    };
    
    return(
        <div>
            <div style={{position:"fixed", top:"0", left:"0", minWidth:"100%", boxShadow: "rgba(0, 0, 0, 0.1) 0px -20px 13px 16px", zIndex:"999999" }}>
            </div>
            {/* ZZAA */}
            {/* <div style={{position:"absolute", top:"0", left:"0", maxWidth:"100%", padding:"10px", zIndex:"9999", display:"flex", justifyContent:"center", backgroundColor:"white", minWidth:"100%", display:"flex", justifyContent:"flex-start"}}> */}
            <div style={{position:"absolute", top:"0", left:"0", maxWidth:"100vw", padding:"10px", zIndex:"9999", display:"flex", justifyContent:"center", flexWrap:"wrap"}}>
                <a href='/'>
                    <img className='header-logo' src={logoSrc}/>
                </a>
                {/* <Menu/> */}
                <div className='menu-web menu-width display-web' style={{position:"absolute", top:"10%", left:"30%", zIndex:"9999"}}>
                    {menuData.map((category, index) => (
                        <li className='dropdown dropdown-category display-web' style={{fontSize:"14px", padding:"20px"}} onMouseOver={() => handleDropdownToggle(index)} onMouseLeave={() => handleDropdownToggle(null)}>
                            <a href="#" className="dropdown-toggle display-web" role="button" aria-haspopup="true" aria-expanded="false" style={{color: dark ? "black" : "white", whiteSpace:"nowrap", padding:"0 20px", fontWeight:"bolder"}}>
                                {category.category}<span className="caret"></span>
                            </a>
                            <ul className={`display-web dropdown-menu${activeDropdownIndex === index ? ' show' : ''} web-submenu`} style={{maxHeight:"60vh", columnCount:"3", columnGap:"30px", whiteSpace:"nowrap", padding:"15px 35px", lineHeight:"2.2em", fontSize:"14px", ...(index > menuData.length - 4 ? { right: 0, left:"auto" } : {}) }}>
                                {category.subcategories.map((subcategory, subIndex) => (
                                    <li key={subIndex}><a href={`/search/${subcategory}`}>{subcategory}</a></li>
                                ))}
                            </ul>
                        </li>
                    ))}
                </div>

                <div className='display-mobile' style={{position:"absolute", top:"0%", right:"0%", padding:"20px", zIndex:"9999", display:"flex", flexDirection:"row", overflowX:"hidden"}}>
                    <div className='display-mobile'>
                        <button style={{border:"none", backgroundColor:"transparent"}} onClick={toggleSidebar}>
                            <i class="fa fa-bars" style={{color:burgerIcon}} aria-hidden="true"></i>
                        </button>
                        <div className={`sidebar ${showSidebar ? 'show' : ''}`} style={{overflowX:"hidden"}}>
                        <nav>
                        <div style={{height:"50px", display:"flex", flexWrap:"wrap", alignContent:"center", justifyContent:"space-between", padding:"0 10px", fontWeight:"bolder"}}>
                                    <div >Destinations</div>
                                    <button onClick={toggleSidebar} style={{maxHeight:"20px", maxWidth:"20px", border:"none", backgroundColor:"transparent"}}>
                                    <svg fill="#000000" height="15px" width="15px" version="1.1" id="Capa_1"  viewBox="0 0 490 490" ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <polygon points="456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 489.292,457.678 277.331,245.004 489.292,32.337 "></polygon> </g></svg>
                                    </button>
                            </div>
                        <div class="faq" id="accordion" style={{width:"100%",overflowX:"hidden"}}>
                            {menuData.map((category, index) => (
                                <div class="card">
                                    <div class="card-header" id="faqHeading-1">
                                        <div class="mb-0">
                                            <h5 class="faq-title" style={{padding:"10px 10px 10px 20px"}} data-toggle="collapse" data-target={`#faqCollapse-${index+1}`} data-aria-expanded="true" data-aria-controls="faqCollapse-1">
                                                {category.category}
                                            </h5>
                                        </div>
                                    </div>
                                    <div id={`faqCollapse-${index+1}`} class="collapse" aria-labelledby="faqHeading-1" data-parent="#accordion">
                                        <div class="card-body" style={{paddingLeft:"0"}}>
                                            <ul>
                                                {category.subcategories.map((subcategory, subIndex) => (
                                                    <li style={{paddingLeft:"0", fontSize:"12px"}} key={subIndex}><a href={`/search/${subcategory}`}>{subcategory}</a></li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))};
                        </div>
                        </nav>
                    </div>
                </div> 
            </div>  
            </div>
            
        </div>
    )
}

export default Header