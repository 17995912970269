import React from "react";

function HomepageCreative({data}){
    return(
        <section className="display-mobile" style={{margin:"10% 0 0 0"}}>
            <img src={data} className="creative-home"/> 
        </section>
    )
}

export default HomepageCreative